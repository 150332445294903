@use "material_colors" as c;

@mixin toolbar() {
    display: flex;
    align-items: stretch;
    flex-wrap: nowrap;
    justify-content: center;
    overflow: hidden;
    background-color: c.$gray-200
}

@mixin horizontal-toolbar() {
    @include toolbar;
    flex-direction: row;
    height: 100%;
}

@mixin vertical-toolbar() {
    @include toolbar;
    flex-direction: column;
}

.toolbar-left {
    // @include toolbar();
    @include vertical-toolbar();
}

.toolbar-top {
    // @include toolbar();
    @include horizontal-toolbar();
}

.toolbar-right {
    // @include toolbar();
    @include vertical-toolbar();
}

.toolbar-bottom {
    // @include toolbar();
    @include horizontal-toolbar();
}
