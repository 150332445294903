$toolbar-min-size: 80%;
$toolbar-max-size: 100%;

.app {
    max-width: 100%;
    max-height: 100%;
    position: fixed;
    display: grid;
    grid-template-areas:    "top top top"
                            "left draw right"
                            "bottom bottom bottom";
    grid-template-rows: auto minmax($toolbar-min-size, $toolbar-max-size) auto;
    grid-template-columns: auto minmax($toolbar-min-size, $toolbar-max-size) auto;
}
  
.draw {
grid-area: draw;
}

.top {
grid-area: top;
}

.left {
grid-area: left;
}

.right {
grid-area: right;
}

.bottom {
grid-area: bottom;
}